#GameTable {
    display: flex;
    flex-direction: column;

    table {
        .date {
            max-width: 10rem;
        }
        .game-name {
            max-width: 20em;
            overflow: hidden;
        }
        .player {
        }
        .stat {
            max-width: 2rem;
        }
    }

    div.center {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    p.buttons {
        display: flex;

        span {
            flex: 1;
            flex-basis: 1;
        }
    }
}

