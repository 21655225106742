#Login {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    #LoginBoxContainer {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 40vh;
    }


    #LoginBox {
        //border: 1px solid black;
        //margin: 1rem;
        //padding: 1rem;
        //border-radius: 0.3rem;
        //background-color: #DDDD88;
    }
}


error-bg=#FFDEDE;

.error {
    display: block;
    background-color: error-bg;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    text-align: center;
    margin-bottom: 0.5rem;
    border-radius: 0.2rem;
    border: 1px solid darken(error-bg, 3%);
    box-shadow: var(--box-shadow) var(--color-shadow);
}

