#KGSAILogo {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: black !important;

    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 2rem;

    &.full {
        font-size: 5rem;
    }

    .sub {
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 12pt;
    }
}
