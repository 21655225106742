#Record {
    display: flex;
    flex-direction: column;
    align-items: center;

    table.details {
        th, td {
            text-align: left;
        }
    }

    .VKPlayerTableContainer {
        margin: 2rem;
    }
    table.VKPlayerTable {
    }


    .pull-right {
        float: right;
    }
}
